<template>
  <div class="boxCon serviceSecurity">
    <div class="title-con">
      <titleMsg titleMsg="多重安全保障"></titleMsg>
      <span class="iconfont icon-jiantou"></span>
    </div>
    <div class="content">
      <div v-for="(list,index) in list"
           :key="index"
           class="contentItem">
        <img :src="list.img"
             alt="">
        <div class="subTitle">{{list.title}}</div>
        <div class="message">{{list.message}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import titleMsg from '@/components/titleMsg'
export default {
  name: 'serviceSecurity',
  components: {
    titleMsg
  },

  data() {
    return {

      list: [
        {
          title: '安全的数据体系',
          img: require('../../../assets/img/1.png'),
          message: '专业的团队，专注互联网系统研发，系统稳定功能强大，数据私有安全可靠'
        },
        {
          title: '全方位的售后服务',
          img: require('../../../assets/img/2.png'),
          message: '配备专属客服人员，一对一指导服务，技术交流群，帮助文档，时刻保障系统运行'
        },
        {
          title: '完善的帮助文档',
          img: require('../../../assets/img/3.png'),
          message: '系统配备多种帮助文档，包括但不仅限于系统说明，操作手册，常见问题，安装说明等等，助您快速搭建平台'
        },
      ]
    }
  },
  computed: {
  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.serviceSecurity {
  background-color: #fff;
}
.title-con {
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-jiantou {
  display: none;
}
.content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 3% 0;
}
.contentItem {
  width: 320px;
  height: 414px;
  background: #fafafa;
  border-radius: 15px;
  padding: 2%;
  margin: 2% 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  img {
    width: 142px;
    height: auto;
  }
}

@media screen and (max-width: 1440px) {
  .contentItem {
    width: 227px;
    height: 294px;
    border-radius: 11px;
    img {
      width: 100px;
      height: auto;
    }
  }
}

@media screen and (max-width: 1024px) {
  .contentItem {
    width: 170px;
    height: 300px;
    border-radius: 8px;
    img {
      width: 76px;
      height: auto;
    }
  }
}

@media screen and (max-width: 768px) {
  .content {
    justify-content: space-between;
  }
}
</style>