<template>
  <div class="target">
    <div class="title-con first">
      <titleMsg titleMsg="关于创狗智云"></titleMsg>
      <span class="iconfont icon-jiantou"></span>
    </div>
    <div class="title-con second">
      <titleMsg titleMsg="创狗智云"></titleMsg>
    </div>
    <div class="title-con third">
      <titleMsg titleMsg="致力于“让运营更简单”为使命"></titleMsg>
      <span class="iconfont icon-jiantou"></span>
    </div>
    <div class="msg four">
      上海创狗科技有限公司，成立于2018年，是助力新体感空间数字转型的互联网科技型企业，主要服务目标对象是会员制门店，为新体感空间提供综合智能场景整体解决方案，创狗智云围绕“以技术为核心，以服务为宗旨，以运营为使命”的理念，融合人工智能、大数据，建立新零售商业模式，帮助企业实现便捷的数字营销和智能管理，打造品牌形象。
      “借助数据，让运营更简单！”创狗智云SaaS系统，操作简洁、智能完备，在传统业务渠道拓客单一，成本限制，缺乏私域流量的背景下，创狗智云，助力新体感空间，变革传统业务流程、打通新零售业务渠道，完善的线上运营功能，清晰化数据管理，为经营提供决策支持，便捷化教练匹配、课程预约等功能，让会员足不出户，尽享数字便捷。
    </div>
    <div class="msg-num five">
      <div class="num-item">
        <div class="num">200+</div>
        <div>累计服务客户</div>
      </div>
      <div class="num-item">
        <div class="num">50+</div>
        <div>技术人员</div>
      </div>
      <div class="num-item">
        <div class="num">20%</div>
        <div>帮助企业减少运营成本</div>
      </div>
    </div>

  </div>
</template>
<script>
import titleMsg from '@/components/titleMsg'
export default {
  name: 'serviceSecurity',
  components: {
    titleMsg
  },


  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
      },
      activeIndex: 0,
      lists: [
        {
          title: '数据安全',
          msg: '存在五星级安全机房中的专属服务器，完善的数据备份和安全机制，保障数据不会丢失或被越权访问。',
          imgUrl: require('../../../assets/img/service_safety_1.png')
        },
        {
          title: '数据安全',
          msg: '存在五星级安全机房中的专属服务器，完善的数据备份和安全机制，保障数据不会丢失或被越权访问。',
          imgUrl: require('../../../assets/img/service_safety_1.png')
        }
      ]
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  methods: {
    onSlideChange(value) {
      this.activeIndex = value.activeIndex
    },
    toPrev() {
      this.swiper.slidePrev()
    },
    toNext() {
      this.swiper.slideNext()
    }
  }
}
</script>
<style lang="scss" scoped>
.target {
  width: 100%;
  padding: 5% 4%;
  background-image: url("../../../assets/img/about_Introduction.png");
  background-repeat: no-repeat;
  background-size: 30% auto;
  background-position: 105% bottom;
  display: flex;
  flex-direction: column;
  margin-right: 20%;
}

.icon-jiantou {
  display: none;
}

.first {
  display: none;
  order: 1;
}
.second {
  order: 2;
}
.third {
  order: 3;
}
.four {
  order: 4;
}
.five {
  order: 5;
}

.msg {
  width: 70%;
  margin: 50px 0;
  color: #101010;
  line-height: 1.8;
  text-align: justify;
}

.msg-num {
  width: 80%;
  display: flex;
  margin: 50px 0;
}
.num-item {
  margin: 0 50px 0 0;
}

.num {
  font-size: 36px;
  font-family: "DINCond-Black";
  font-weight: 400;
  color: #000000;
  font-size: 36px;
  font-weight: bold;
  background-image: linear-gradient(133deg, #2861ae 0%, #34b05d 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1300px) {
}

@media screen and (max-width: 1024px) {
  .target {
    background-color: #fff;
    background-size: 50% auto;
    background-position: 115% bottom;
  }
  .title-con {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .second {
    order: 3;
  }
  .third {
    order: 4;
  }
  .four {
    order: 2;
  }

  .title-con {
    justify-content: flex-start;
  }
  .icon-jiantou {
    display: block;
  }
  .msg {
    width: 100%;
  }
  .msg-num {
    flex-direction: column;
  }
  .num-item {
    margin: 15px 0;
  }

  .num {
    width: 15%;
    font-size: 26px;
    background-image: linear-gradient(133deg, #2861ae 0%, #34b05d 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>