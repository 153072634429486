<template>
  <div class="first">
    <titleMsg titleMsg="智能舞蹈场馆"
              center></titleMsg>
    <div class="sub-title">大数据智能化场馆系统，颠覆传统单一模式，助力企业实现高效便捷的管理，轻松打造个性化的营销服务</div>

    <div class="content">
      <div class="content-msg">
        <div class="msg-content">
          <titleMsg titleMsg="课程顾问"></titleMsg>
          <div class="msg">
            1.系统在线智能辅助跟客管理，随时随地方便快捷地维护客户关系，提升跟客质量；<br>
            2.线上智能化采集客户资料，一键同步系统备份，客户资料云端安全存储防丢失；<br>
            3.大数据助力课程顾问全渠道拓客，大幅提升可拓展用户数量和拓展效率。
          </div>
        </div>
        <div class="msg-content">
          <titleMsg titleMsg="场馆"></titleMsg>
          <div class="msg">
            1.将传统场馆与现代科技相结合，实现智能舞蹈场馆控制；<br>
            2.配合相关硬件可在场馆进行如人脸识别、人流检测、无感知签到、舞蹈动作标准检测、心率检测等智能操作，满足用户不同需求，并提升科技体验感。
          </div>
        </div>
      </div>
      <div class="center-img">
      </div>
      <div class="content-msg">
        <div class="msg-content">
          <titleMsg titleMsg="会员"></titleMsg>
          <div class="msg">
            1.多功能会员后台，在线进行查看课表/约课/签到/评价等，优化用户体验流程；<br>
            2.线上业务办理，会员可在线进行转卡/延期/停卡/转店等自助服务，省去第三方操作等待流程，节约时间成本；<br>
            3.拥有线上完善的会员成长体系，多种个性化会员权益和交互活动，享受不一样的会员体验。
          </div>
        </div>
        <div class="msg-content">
          <titleMsg titleMsg="教练">
          </titleMsg>
          <div class="msg">
            1.智能后台为教练提供打卡签到、课程提醒、日程事项通知等日常功能，提升教练的教学体验；<br>
            2.支持课堂质量在线评价反馈功能，教练可实时获得课堂评价反馈，与学员互动交流，升级课堂教学质量和氛围。
          </div>
        </div>
      </div>
    </div>

    <Tabs class="tabs">
      <TabPane label="课程顾问"
               name="name1">
        <div class="msg">
          1.系统在线智能辅助跟客管理，随时随地方便快捷地维护客户关系，提升跟客质量；<br>
          2.线上智能化采集客户资料，一键同步系统备份，客户资料云端安全存储防丢失；<br>
          3.大数据助力课程顾问全渠道拓客，大幅提升可拓展用户数量和拓展效率。
        </div>
      </TabPane>
      <TabPane label="会员"
               name="name2">
        <div class="msg">
          1.多功能会员后台，在线进行查看课表/约课/签到/评价等，优化用户体验流程；<br>
          2.线上业务办理，会员可在线进行转卡/延期/停卡/转店等自助服务，省去第三方操作等待流程，节约时间成本；<br>
          3.拥有线上完善的会员成长体系，多种个性化会员权益和交互活动，享受不一样的会员体验。
        </div>
      </TabPane>
      <TabPane label="教练"
               name="name3">
        <div class="msg">
          1.智能后台为教练提供打卡签到、课程提醒、日程事项通知等日常功能，提升教练的教学体验；<br>
          2.支持课堂质量在线评价反馈功能，教练可实时获得课堂评价反馈，与学员互动交流，升级课堂教学质量和氛围。
        </div>
      </TabPane>
      <TabPane label="场馆"
               name="name4">
        <div class="msg">
          1.将传统场馆与现代科技相结合，实现智能舞蹈场馆控制；<br>
          2.配合相关硬件可在场馆进行如人脸识别、人流检测、无感知签到、舞蹈动作标准检测、心率检测等智能操作，满足用户不同需求，并提升科技体验感。
        </div>
      </TabPane>
    </Tabs>

  </div>
</template>
<script>
import titleMsg from '@/components/titleMsg'
export default {
  name: 'first',
  components: {
    titleMsg
  },
  data () {
    return {

    }
  }
}
</script>
<style scoped lang="scss">
.first {
  width: 100%;
  padding: 8% 4% 4% 4%;
  background-color: #fafafa;
}
.sub-title {
  text-align: center;
  margin: 2% 0;
}
.content {
  width: 94%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.content-msg {
  width: 35%;
  padding: 0 2%;
  box-sizing: border-box;
}
.center-img {
  width: 30.5%;
  height: 0;
  padding: 20% 0;
  background-image: url("../../../assets/img/product_mobile.png");
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
}
.msg-content {
  width: 100%;
  margin: 10% 0;
}
.msg {
  padding: 4% 0;
  box-sizing: border-box;
}
.tabs {
  display: none;
}

@media screen and (max-width: 1400px) {
  .msg-content {
    width: 100%;
    margin: 6% 0;
  }
  .center-img {
    min-width: 384px;
    min-height: 508px;
  }
}
@media screen and (max-width: 1024px) {
  .first {
    padding: 12% 4% 4% 4%;
    box-sizing: border-box;
  }
  .center-img {
    min-width: 96%;
    min-height: 0;
    padding: 50% 0;
    background-image: url("../../../assets/img/product_mobile_weixin.png");
  }
  .content-msg {
    display: none;
  }
  .tabs {
    display: block;
  }
}
</style>
<style lang="scss">
.tabs {
  .ivu-tabs-ink-bar {
    background-color: #222222;
  }
}
</style>