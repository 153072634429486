<template>
  <div class="serverPro">
    <div class="title-con">
      <titleMsg titleMsg="服务流程"></titleMsg>
      <span class="iconfont icon-jiantou"></span>
    </div>
    <div class="web-con">
      <div class="pro-item"
           v-for="(list,index) in serviseList"
           :key="index">
        <p class="tit-top">{{list.titTop}}</p>
        <img class="pro-img"
             src="../../../assets/img/lALPD2eDOsY10QbMqyg_40_171.png"
             alt="">
        <p class="tit-bottom">{{list.titBotto}}</p>
      </div>
    </div>
    <div class="mobile-con">
      <div class="mobile-pro-item"
           v-for="(list,index) in serviseList"
           :key="index">
        <p class="tit-top">{{list.titTop}}</p>
        <div class="line-con">
          <div class="circle"></div>
          <div class="line">
            <div class="line-icon"
                 :style="{display:index ===serviseList.length-1?'block':'none' }"></div>
          </div>
        </div>
        <p class="tit-bottom">{{list.titBotto}}</p>
      </div>
    </div>
  </div>
</template>
<script>
import titleMsg from '@/components/titleMsg'
export default {
  name: 'serviceProcess',
  components: {
    titleMsg
  },
  data () {
    return {
      serviseList: [
        {
          titTop: '售前咨询',
          titBotto: '通过微信公众号、网站、电话等官方联系方式咨询客服',
        },
        {
          titTop: '需求分析',
          titBotto: '挖掘企业特性，精准分析业务端需求及用户端需求',
        },
        {
          titTop: '签订合同',
          titBotto: '保障双方权益，签订正规服务合同',
        },
        {
          titTop: '使用培训',
          titBotto: '一对一培训，保证产品功能快速上手使用',
        },
        {
          titTop: '售后服务',
          titBotto: '专业售后客服对接及时解决企业问题',
        },
        {
          titTop: '增值服务',
          titBotto: '可定制化服务定制化新功能',
        },
      ]
    }
  }
}
</script>
<style scoped lang="scss">
.serverPro {
  width: 100%;
  padding: 5% 4%;
  background-color: #fafafa;
}
.title-con {
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-jiantou {
  display: none;
}
.web-con {
  width: 1500px;
  height: 770px;
  margin: 0 auto;
  background-image: url("../../../assets/img/lALPD4d8rpEvXvbNAwPNBeA_1504_771.png");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.pro-item {
  width: 140px;
  height: 350px;
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 1%;
}
.pro-item:nth-child(1) {
  top: 38.5%;
  left: 0;
}
.pro-item:nth-child(2) {
  top: 28.5%;
  left: 13%;
}
.pro-item:nth-child(3) {
  top: 31%;
  left: 36%;
}
.pro-item:nth-child(4) {
  top: 15%;
  left: 53%;
}
.pro-item:nth-child(5) {
  top: 10%;
  left: 75%;
}
.pro-item:nth-child(6) {
  top: -2%;
  left: 90%;
}
.pro-img {
  width: 44px;
  height: 170px;
}
.tit-top {
  min-width: 60px;
  font-weight: bold;
  text-align: center;
}
.tit-bottom {
  color: #ffffff;
  text-align: center;
}

.mobile-con {
  width: 100%;
  margin: 10% 0;
  display: none;
}
.mobile-pro-item {
  width: 100%;
  height: 90px;
  display: flex;
}
.line-con {
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // padding-top: 5px;
}
.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #222;
}
.line {
  width: 0;
  height: 100%;
  border-right: 1px solid #222222;
  margin: 0 30px;
  position: relative;
}
.line-icon {
  width: 0;
  height: 20px;
  border-right: 1px solid #222;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: rotate(40deg);
  transform-origin: left bottom;
}

@media screen and (max-width: 1600px) {
  .web-con {
    width: 1300px;
    height: 668px;
  }
  .pro-item:nth-child(1) {
    top: 36.5%;
  }
  .pro-item:nth-child(2) {
    top: 27%;
  }
  .pro-item:nth-child(3) {
    top: 29%;
  }
  .pro-item:nth-child(4) {
    top: 13%;
  }
  .pro-item:nth-child(5) {
    top: 8%;
  }
  .pro-item:nth-child(6) {
    top: -4%;
    left: 89%;
  }
}

@media screen and (max-width: 1400px) {
  .pro-img {
    width: 33px;
    height: 127px;
  }
  .web-con {
    width: 900px;
    height: 462px;
  }
  .pro-item {
    width: 100px;
    height: 300px;
  }
  .pro-item:nth-child(1) {
    top: 34.5%;
  }
  .pro-item:nth-child(2) {
    top: 25.5%;
  }
  .pro-item:nth-child(3) {
    top: 25%;
  }
  .pro-item:nth-child(4) {
    top: 11%;
  }
  .pro-item:nth-child(5) {
    top: 6%;
  }
  .pro-item:nth-child(6) {
    top: -7%;
  }
}

@media screen and (max-width: 1024px) {
  .serverPro {
    background-color: #ffffff;
  }
  .mobile-con {
    display: block;
  }
  .title-con {
    justify-content: flex-start;
  }
  .icon-jiantou {
    display: block;
  }
  .web-con {
    display: none;
  }
  .tit-bottom {
    color: #999999;
    text-align: left;
  }
}
</style>