<template>
  <div class="page"
       @scroll.passive="getScroll($event)">
    <headertab :activeBar="1"
               :hiddenHeader="hiddenHeader"></headertab>
    <div class="swiper"></div>
    <div class="con">
      <first></first>
      <second></second>
      <third></third>
      <four></four>
      <signUp></signUp>
      <bottom></bottom>
    </div>

  </div>
</template>

<script>
import headertab from '@/components/headertab'
import first from './components/first'
import second from './components/second'
import third from './components/third'
import four from './components/four'
import signUp from '@/components/signUp'
import bottom from '@/components/bottom'
import { globalMethods } from '../mixnis/index'

export default {
  name: 'Home',
  components: {
    headertab,
    first,
    second,
    third,
    four,
    signUp,
    bottom
  },
  mixins: [globalMethods],

  data() {
    return {
      swiperValue: 0,
      swipers: [
        '../../assets/img/product_banner.png',
      ],
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
      },
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  methods: {
  }
}
</script>
<style scoped lang="scss">
.page {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.swiper {
  width: 100%;
  height: 600px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../assets/img/product_banner.png");
}
.con {
  width: 100%;
  // height: 2300px;
  border-top-left-radius: 150px;
  position: absolute;
  top: 460px;
  left: 0;
  background-color: #fafafa;
  overflow: hidden;
  // z-index: ;
}

@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
  .swiper {
    width: 100%;
    height: 266px;
    background-image: url("../../assets/img/product_mobile_banner.png");
  }
  .con {
    border-top-left-radius: 75px;
    top: 200px;
  }
}
</style>
