export const m = {
  // 头部导航
  navigationBar: [
    {
      id: 0,
      name: "首页",
      path: "/",
    },
    {
      id: 1,
      name: "产品",
      path: "/Product",
    },
    {
      id: 2,
      name: "服务",
      path: "/Server",
    },
    {
      id: 3,
      name: "我们",
      path: "/Team",
    },
    {
      id: 4,
      name: "免费注册",
      path: "/Tryout",
    },
    // {
    //   id: 5,
    //   name: "登陆",
    //   path: "",
    // },
  ],
  signin: "登录",
  signup: "免费注册",
  home: {
    FirstLabel: {
      title1: "创狗智云,",
      title2: "1个产品体系4个维度",
      labels: [
        {
          title: "体系化",
          img: require("../assets/img/lALPD3W5M6kGMIN4eA_120_120.png"),
          msg: "智能化系统，让运营更简单",
        },
        {
          title: "智能化",
          img: require("../assets/img/lALPD3zUMPKQ-pN4eA_120_120.png"),
          msg: "封装数据，从管理到服务",
        },
        {
          title: "数据化",
          img: require("../assets/img/lALPD3zUMPKTMIZ4eA_120_120.png"),
          msg: "大数据分析，助力企业发展",
        },
        {
          title: "服务化",
          img: require("../assets/img/lALPD26eNl9-CHB4eA_120_120.png"),
          msg: "专业团队，服务至上",
        },
      ],
    },
    secondLabel: {
      first: {
        title1: "Web管理端",
        title2: "大数据助力企业发展",
        list: [
          "精细会员管理",
          "分权限管理模式",
          "场馆管理",
          "员工管理",
          "在线一键排课",
          "多维数据报表",
          "电子合同",
          "短信群发",
          "多种营销玩法",
          "...",
        ],
      },
      second: {
        title1: "微信约课系统",
        title2: "全面覆盖用户及企业需求",
        list: [
          "快速约课",
          "约课记录随时查看",
          "签到打卡",
          "教室租赁",
          "积分权益",
          "自助卡服务",
          "电子合同在线签",
          "...",
        ],
      },
    },
    third: {
      title: "借助数据 让运营更简单",
      msg: "了解更多",
    },
    four: {
      title: "企业个性化需求，多种角色全面覆盖",
      list: [
        "1.一站式智能工作台，系统助力对各环节进行高效便捷的数字化管理",
        "2.系统分维度统计分析客户数据，为管理者决策提供可行性依据",
        "3.员工成长轨迹智能分析，提高门店人员管理效率",
      ],
    },
  },
  bottom: {
    workTime: "工作时间:  10:00 - 19:00（工作日）",
    logoName: "创狗智云 -助力新体感空间数字转型的互联网科技型企业",
    logoSign: "沪ICP备2021019839号",
  },
}
