<template>
  <div class="contactUs boxCon">
    <titleMsg titleMsg="企业文化"></titleMsg>
    <div class="content">
      <div v-for="(list,index) in list"
           :key="index"
           class="contentItem">
        <div class="subTitle">{{list.title}}</div>
        <div class="message">{{list.message}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import titleMsg from '@/components/titleMsg'
export default {
  name: 'serviceSecurity',
  components: {
    titleMsg
  },

  data() {
    return {
      list: [
        {
          title: '使命',
          message: '借助数据，让运营更简单'
        },
        {
          title: '愿景',
          message: '成为国内新体感空间综合场景整体解决方案提供者和数字化运营、智能化、开放式、创造式管理的新型互联网企业'
        },
        {
          title: '价值观',
          message: '诚以创新，简以谋远，云端智控，价值共赢海纳百川、融汇物外、善思笃行，止于臻善'
        },
      ]
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.contactUs {
  width: 100%;
  margin: 3% 0;
  background-color: #fafafa;
}
.content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 3% 0;
}
.contentItem {
  width: 320px;
  height: 200px;
  padding: 1%;
  background: #ffffff;
  border-radius: 15px;
  margin: 5% 0;
}
@media screen and (max-width: 1440px) {
  .contentItem {
    width: 290px;
    height: 165px;
    border-radius: 11px;
  }
}
@media screen and (max-width: 1024px) {
  .contentItem {
    width: 206px;
    height: 180px;
  }
}

@media screen and (max-width: 500px) {
  .title {
    font-size: 20px;
  }
  .contact-msg {
    width: 80%;
  }
  .msg {
    height: 90px;
    font-size: 16px;
  }
}
</style>