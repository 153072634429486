<template>
  <div class="page"
       @scroll.passive="getScroll($event)">
    <headertab :activeBar="3"
               :hiddenHeader="hiddenHeader"></headertab>
    <div class="top-img"></div>
    <target></target>
    <contactUs></contactUs>
    <bottom></bottom>
  </div>
</template>

<script>
import headertab from '@/components/headertab'
import target from './components/target'
import contactUs from './components/contactUs'
import bottom from '@/components/bottom'
import { globalMethods } from '../mixnis/index'

export default {
  name: 'Server',
  components: {
    headertab,
    target,
    contactUs,
    bottom
  },
  mixins: [globalMethods],

  data() {
    return {
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  methods: {
  }
}
</script>
<style scoped lang="scss">
.page {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.top-img {
  width: 100%;
  height: 590px;
  background-image: url("../../assets/img/about_mobile_banner.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 768px) {
  .top-img {
    height: 310px;
  }
}
</style>
