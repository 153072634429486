<template>
  <div class="four">
    <div class="title-con">
      <titleMsg titleMsg="企业化个性需求"></titleMsg>
      <span class="title-split">,</span>
      <titleMsg titleMsg="多种角色全面覆盖"></titleMsg>

    </div>

    <div class="four-web">

      <div class="four-msg">
        <Tooltip class="box"
                 theme="light"
                 max-width="600"
                 :transfer="true"
                 v-for="(list,index) in tips"
                 :key="index"
                 :class="list.class"
                 :placement="list.position">
          <div slot="content"
               class="boxCon"
               v-html="list.content"></div>
        </Tooltip>
      </div>
    </div>

    <div class="four-mobile">

      <swiper ref="mySwiper"
              :options="swiperOptions"
              @slideChange="onSlideChange">
        <swiper-slide class="slide"
                      v-for="(list,index) in tips"
                      :key="index">
          <div class="slide-msg">

            <div class="sub-tit">{{list.title}}</div>
            <div v-html="list.content"
                 class="sub-content"></div>
          </div>
          <div class="slide-img">
            <img :src="list.img"
                 alt="">
          </div>
        </swiper-slide>
        <div class="swiper-pagination"
             slot="pagination">
          <div class="pagination-text">
            <span class="active">{{activeIndex+1}}</span>/7
          </div>
          <div class="pagination-info">
            <span v-for="(list,index) in tips"
                  :key="index"
                  :class="{active:activeIndex===index?true:false}"></span>
          </div>
        </div>
      </swiper>
    </div>
  </div>
</template>
<script>
import titleMsg from '@/components/titleMsg'
export default {
  components: {
    titleMsg
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper.activeIndex
    }
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
      },
      activeIndex: 0,
      tips: [
        {
          title: '管理',
          class: 'manage',
          content: "1.一站式智能工作台，系统助力对各环节进行高效便捷的数字化管理<br> 2.系统分维度统计分析客户数据，为管理者决策提供可行性依据<br>3.员工成长轨迹智能分析，提高门店人员管理效率",
          position: 'left',
          img: require('../../../assets/img/home_lead.png')
        },
        {
          title: '师资',
          class: 'teacher',
          content: '1.提供打卡签到、上课提醒、日程通知等日常功能<br>2.在线进行课堂评价、反馈，提升课堂教学质量',
          position: 'left',
          img: require('../../../assets/img/home_teacher.png')
        },
        {
          title: '前台',
          class: 'frontdesk',
          content: ' 1.智能前台操作系统，功能齐全，提高线下门店服务效率<br>2.规范化前台服务流程，完善用户体验',
          position: 'left',
          img: require('../../../assets/img/home_reception.png')
        },
        {
          title: '人事',
          class: 'personnel',
          content: '1.系统提供线上考勤、打卡、审批等功能，员工管理高效便捷<br>2.员工成长轨迹自动生成分析，科学化进行人事决策',
          position: 'left',
          img: require('../../../assets/img/home_personnel.png')
        },
        {
          title: '课程顾问',
          class: 'consultant',
          content: '1.智能化采集客户资料，一键同步系统备份，防止关键信息丢失<br>2.系统辅助跟客管理，随时随地维护客户关系<br>3.大数据全渠道拓客助力，大幅提升可拓展用户数量',
          position: 'left',
          img: require('../../../assets/img/home_consultant.png')
        },
        {
          title: '财务',
          class: 'Finance',
          content: '1.简化财务申报、审批流程。节省人力和时间成本<br>2.智能生成可视化财务数据报表，企业财务状况一目了然',
          position: 'left',
          img: require('../../../assets/img/home_money.png')
        },
        {
          title: '自定义',
          class: 'other',
          content: '1.可根据企业个性化需求，定制自定义角色及功能',
          position: 'left',
          img: require('../../../assets/img/home_custom.png')
        },
      ]
    }
  },
  methods: {
    onSlideChange(value) {
      this.activeIndex = value.activeIndex
    }
  }
}
</script>
<style scoped lang="scss">
.four {
  width: 100%;
  padding: 5% 4%;
  box-sizing: border-box;
  background-color: rgba(250, 250, 250, 1);
}
.four-title {
  width: 100%;
  margin: 10px;
  text-align: center;
}

.four-web {
  margin: 8% 0;
}
.four-msg {
  width: 57%;
  height: 0;
  padding-bottom: 57%;
  margin: 50px auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../../assets/img/home_role.png");
  position: relative;
}
.box {
  position: absolute;
}
.box-content {
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 15px;
}
.manage {
  width: 13.5%;
  height: 13.5%;
  top: 20%;
  left: 0;
}
.teacher {
  width: 13.5%;
  height: 13.5%;
  top: 0%;
  right: 28%;
}
.consultant {
  width: 13.5%;
  height: 13.5%;
  top: 71%;
  left: 3%;
}
.frontdesk {
  width: 9%;
  height: 9%;
  top: 49%;
  left: 20.5%;
}
.personnel {
  width: 9%;
  height: 9%;
  top: 82%;
  left: 39%;
}
.other {
  width: 11%;
  height: 11%;
  top: 80%;
  left: 78%;
}
.Finance {
  width: 10%;
  height: 10%;
  top: 33%;
  left: 90%;
}

.four-mobile {
  display: none;
  width: 100%;
  margin: 8% 0;
}
.title-con {
  display: flex;
  justify-content: center;
  align-items: center;
}
.title-split {
  font-size: 44px;
}
.slide {
  width: 100%;
  // height: 500px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 80px 0;
  align-items: center;
  padding-left: 4%;
  box-sizing: border-box;
}
.slide-msg {
  width: 50%;
}
.sub-tit {
  font-weight: bold;
  margin-bottom: 20px;
}
.sub-content {
  text-align: justify;
  line-height: 1.8;
}
.slide-img {
  width: 43%;
  height: 0;
  padding: 24.5% 0;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../../assets/img/home_mobile_using_bg.png");
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 43.3%;
  }
}
.swiper-pagination {
  width: 92%;
  bottom: 0;
  left: 8%;
  font-weight: bold;
}
.pagination-text {
  text-align: left;
}
.pagination-text .active {
  font-size: 24px;
}
.pagination-info {
  width: 91%;
  height: 4px;
  display: flex;
  background: #f5f5f5;
  border-radius: 2px;
}
.pagination-info span {
  display: block;
  flex: 1;
  height: 100%;
  border-radius: 2px;
  background: transparent;
}
.pagination-info span.active {
  background: #222222;
}

@media screen and (max-width: 768px) {
  .four {
    background-color: #fff;
  }
  .four-web {
    display: none;
  }
  .four-mobile {
    display: block;
  }

  .title-con {
    display: block;
  }
  .title-split {
    display: none;
  }

  .slide {
    margin: 0 0 15% 0;
  }

  .sub-content {
    // text-align: justify;
    font-size: 14px;
  }
}
</style>