<template>
  <div class="second">
    <div class="web">
      <div class="second-first">
        <div class="left">
          <!-- <div class="second-tit">{{$t('m.home.secondLabel.first.title1')}}</div>
          <div class="second-tit">{{$t('m.home.secondLabel.first.title2')}}</div> -->
          <div class="title-con">
            <titleMsg titleMsg="Web管理端"></titleMsg>
            <titleMsg titleMsg="大数据助力企业发展"></titleMsg>
          </div>
          <div class="second-list"
               v-for="(list,index) in $t('m.home.secondLabel.first.list')"
               :key="index">{{list}}</div>
        </div>
        <div class="right">
          <img src="@/assets/img/home_web.png"
               alt="">
        </div>
      </div>

      <div class="second-second">
        <div class="left">
          <img src="@/assets/img/home_mobile.png"
               alt="">
        </div>
        <div class="right">
          <div class="title-con">
            <titleMsg titleMsg="微信约课系统"></titleMsg>
            <titleMsg titleMsg="全面覆盖用户及企业需求"></titleMsg>
          </div>
          <div class="second-list"
               v-for="(list,index) in $t('m.home.secondLabel.second.list')"
               :key="index">{{list}}</div>
        </div>
      </div>
    </div>

    <div class="mobile">
      <swiper ref="mySwiper"
              :options="swiperOptions"
              @slideChange="onSlideChange">
        <swiper-slide class="slide">
          <titleMsg titleMsg="Web管理端"></titleMsg>
          <div class="title-con">

            <titleMsg titleMsg="大数据助力企业发展"></titleMsg>
            <span style="margin-left:15px"
                  class="iconfont icon-jiantou"></span>
          </div>
          <div class="mobile-con">
            <div class="mobile-img-left">
              <img src="@/assets/img/home_mobile_web.png"
                   alt="">
            </div>
            <div class="mobile-list-con">
              <div class="mobile-list mobile-list-left"
                   v-for="(list,index) in $t('m.home.secondLabel.first.list')"
                   :key="index">{{list}}</div>
            </div>

          </div>

        </swiper-slide>
        <swiper-slide class="slide">
          <titleMsg titleMsg="微信约课系统"></titleMsg>
          <div class="title-con">

            <titleMsg titleMsg="全面覆盖用户及企业需求"></titleMsg>
            <span style="margin-left:15px"
                  class="iconfont icon-jiantou"></span>
          </div>
          <div class="mobile-con">
            <div class="mobile-img-right">
              <img src="@/assets/img/home_mobile_wexin.png"
                   alt="">
            </div>
            <div class="mobile-list-con">
              <div class="mobile-list mobile-list-right"
                   v-for="(list,index) in $t('m.home.secondLabel.second.list')"
                   :key="index">{{list}}</div>
            </div>

          </div>

        </swiper-slide>
        <div class="swiper-pagination"
             slot="pagination">
          <div class="pagination-text">
            <span class="active">{{activeIndex+1}}</span>/2
          </div>
          <div class="pagination-info">
            <span v-for="(list,index) in 2"
                  :key="index"
                  :class="{active:activeIndex===index?true:false}"></span>
          </div>
        </div>
      </swiper>
    </div>

  </div>
</template>
<script>
import titleMsg from '@/components/titleMsg'
export default {
  components: {
    titleMsg
  },
  data () {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
      },
      activeIndex: 0
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper.activeIndex
    }
  },
  mounted () {
    // console.log(this.$refs.mySwiper)
  },
  methods: {
    onSlideChange (value) {
      this.activeIndex = value.activeIndex
    },
    toPrev () {
      this.swiper.slidePrev()
    },
    toNext () {
      this.swiper.slideNext()
    }
  }
}
</script>
<style scoped lang='scss'>
.second {
  width: 100%;
  padding: 5% 4%;
  box-sizing: border-box;
}
.web {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mobile {
  display: none;
}

.second-first,
.second-second {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
}
.second-tit {
  font-size: 44px;
  font-weight: bold;
  color: #222;
}
.second-list {
  margin: 25px 0;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.second-list::before {
  display: block;
  content: "";
  width: 30px;
  height: 10px;
  margin-right: 30px;
}
.second-first .second-list::before {
  background-color: #2861ae;
}
.second-second .second-list::before {
  background-color: #34b05d;
}
.right {
  padding-left: 4%;
}
.right img,
.left img {
  display: block;
  width: 100%;
  height: auto;
}

.slide {
  width: 92%;
  overflow: hidden;
}
.slide {
  // box-sizing: border-box;
  // padding: 50px 0 50px 8%;
}

.mobile-tit {
  font-size: 30px;
  font-weight: bold;
  margin: 15px 0;
  color: #222;
}
.mobile-con {
  width: 100%;
  height: 1000px;
  padding: 20px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: -3px 11px 24px 0px rgba(218, 218, 218, 0.14);
  border-radius: 10px;
}
.mobile-img-right,
.mobile-img-left {
  margin: 20px 0;
  // position: relative;
}
.mobile-img-left {
  width: 940px;
  height: 618px;
  img {
    width: 100%;
    height: 100%;
  }
}
.mobile-img-right {
  width: 662px;
  height: 659px;
  img {
    width: 100%;
    height: 100%;
  }
}
.mobile-list-con {
  width: 60%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 15px;
}
.mobile-list {
  width: 48%;
  height: 60px;
  display: flex;
  align-items: center;
  word-break: break-all;
}
.mobile-list::before {
  display: block;
  content: "";
  min-width: 10px;
  height: 10px;
  margin-right: 15px;
}
.mobile-list-left::before {
  background-color: #2861ae;
}

.mobile-list-right::before {
  background-color: #34b05d;
}

.swiper-pagination {
  bottom: 7%;
  right: 7%;
  font-weight: bold;
}
.pagination-text .active {
  font-size: 24px;
}
.pagination-info {
  display: flex;
  width: 90px;
  height: 4px;
  background: #f5f5f5;
  border-radius: 2px;
}
.pagination-info span {
  display: block;
  width: 45px;
  height: 4px;
  border-radius: 2px;
  background: transparent;
}
.pagination-info span.active {
  background: #222222;
}

@media screen and (max-width: 1024px) {
  .second {
    padding: 5% 0;
  }
  .web {
    display: none;
  }
  .mobile {
    display: block;
  }
  .slide {
    padding-left: 4%;
  }
  .title-con {
    display: flex;
    align-items: flex-end;
  }
  .mobile-list {
    font-size: 14px;
  }
}
// @media screen and (max-width: 769px) {
//   .mobile-img-left {
//     width: 706px;
//     height: 464px;
//   }
//   .mobile-img-right {
//     width: 497px;
//     height: 495px;
//   }
// }

@media screen and (max-width: 600px) {
  .mobile-tit {
    font-size: 23px;
  }
  .mobile-con {
    height: 750px;
    padding: 15px;
  }
  .mobile-list-con {
    width: 100%;
    margin: 0;
  }
  .mobile-list::before {
    z-index: 999;
    margin-right: 5px;
  }
  .mobile-img-left {
    width: 550px;
    height: 357px;
  }
  .mobile-img-right {
    width: 382px;
    height: 381px;
  }
}
</style>