<template>
  <div class="page"
       @scroll.passive="getScroll($event)">

    <headertab :activeBar="-1"
               :hiddenHeader="hiddenHeader"></headertab>
    <div class="sign-up">
      <div class="box">
        <div class="title">请填写试用申请表</div>
        <div class="msg">在您提交试用表格后，您的专属客服将会第一时间与您取得联系，沟通软件版本及安装试用等相关信息，请保持通讯畅通，感谢您的支持！</div>
        <Form ref="formData"
              :model="formData"
              :rules="rules"
              @submit.native.prevent>
          <FormItem prop="name">
            <div class="item">
              <input type="text"
                     v-model="formData.name"
                     placeholder="品牌名称">
            </div>
          </FormItem>
          <FormItem prop="contact">
            <div class="item">
              <input type="text"
                     v-model="formData.contact"
                     placeholder="联系人">
            </div>
          </FormItem>
          <FormItem prop="contactphone">
            <div class="item">
              <input type="text"
                     v-model="formData.contactphone"
                     placeholder="手机号">
            </div>
          </FormItem>
          <!-- <div class="item">
            <input type="text"
                   v-model="formData.userValidCode"
                   placeholder="验证码">
            <div class="code">
              <span @click="getCode">{{message}}</span>
            </div>
          </div> -->
          <div class="terms-of-use">注册即表示同意创狗智云<span>《使用条款》</span></div>
          <FormItem>

            <div class="btn-con">
              <Button class="sign-up-btn"
                      @click="handleSubmit('formData')"
                      :loading="loading">提交申请</Button>
            </div>
          </FormItem>

          <div class="info">* 提交申请后会有工作人员和您联系</div>
        </Form>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import headertab from '@/components/headertab'
import bottom from '@/components/bottom'
import { globalMethods } from '../mixnis/index'
import { sendMsg, signIn } from '@/api/signUp'
//
export default {
  name: 'Server',
  components: {
    headertab,
    bottom,
  },
  mixins: [globalMethods],

  data() {
    return {
      message: '获取验证码',
      timer: null,
      timing: false,
      formData: {
        name: '',//品牌名称
        contact: '',//联系人
        contactphone: '',//联系方式
        validCode: '',
        uuid: '',
      },
      rules: {
        name: [{ required: true, message: '请输入品牌名称', trigger: 'blur' }],
        contact: [{ required: true, message: '请输入品牌名称', trigger: 'blur' }],
        contactphone: [{ required: true, validator: this.$phoneValidate, trigger: 'blur' }]
      },
      loading: false
    }
  },
  created() {
  },
  methods: {
    getCode() {
      if (this.timing) return ''
      sendMsg({ contactphone: this.formData.userPhone }).then(res => {
        console.log(res);
        if (res.status === 200) {
          this.formData.userPhone = res.data.iphone
          this.formData.userUuid = res.data.uuid
          let time = 60
          this.timing = true
          window.clearInterval(this.timer)
          this.timer = window.setInterval(() => {
            if (time > 0) {
              time--
              this.message = time + 's'
            } else {
              this.message = '获取验证码'
              window.clearInterval(this.timer)
              this.timer = null
              this.timing = false
            }
          }, 1000)
        } else {
          console.log(this.$Message)
          this.$Message.error('获取验证码失败！')
        }
      })

    },

    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.loading = true
          signIn(this.formData).then(() => {
            this.$Message.success('注册成功，稍后将与您联系！')
            this.$refs[name].resetFields()
            this.loading = false
          }).catch(() => {
            this.loading = false
          })
        } else {
          this.$Message.error('信息不完整！');
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.page {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  background: #fafafa;
}

.sign-up {
  width: 100%;
  margin: 100px auto;
  padding: 5% 4%;
  box-sizing: border-box;
}
.box {
  width: 768px;
  height: auto;
  margin: 0 auto;
  background-color: #fff;
  padding: 3%;
  box-sizing: border-box;
  border-radius: 3%;
  color: #222;
  font-size: 16px;
}
.title {
  width: 100%;
  font-size: 34px;
  font-weight: bold;
  margin: 15px 0;
}
.item {
  width: 100%;
  height: 85px;
  margin: 15px 0;
  border-bottom: 1px solid #222222;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 2% 2%;
  box-sizing: border-box;
  input {
    width: 80%;
    border: 0;
    outline: medium;
  }
}
.code {
  min-width: 90px;
  font-size: 14px;
  color: #2861ae;
}

.terms-of-use {
  font-size: 14px;
  span {
    color: #2861ae;
  }
}
.btn-con {
  display: flex;
  justify-content: center;
}
.sign-up-btn {
  width: 430px;
  height: 42px;
  line-height: 42px;
  border: 0;
  text-align: center;
  background: linear-gradient(60deg, #2861ae, #34b05d);
  border-radius: 20px;
  font-size: 16px;
  color: #fff;
  margin: 30px auto;
}
.info {
  font-size: 14px;
  text-align: center;
  color: #2861ae;
}

@media screen and (max-width: 1024px) {
  .box {
    width: 80%;
  }
}
@media screen and (max-width: 768px) {
  .page {
    background-color: #fff;
  }
  .box {
    width: 100%;
  }
  .item {
    height: 48px;
    border: 1px solid #222222;
  }
  .sign-up-btn {
    width: 100%;
    border-radius: 0;
    font-size: 16px;
    color: #fff;
    margin: 30px auto;
  }
}
</style>
