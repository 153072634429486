import service from "./service"
import config from "../config"

export const sendMsg = (data) => {
  return service({
    url: `${config.baseUrl}/login/regBrandSendCode`,
    method: "post",
    data,
  })
}

//布鲁盖注册
export const signIn = (data) => {
  return service({
    url: `/brand/new/regBrand`,
    method: "post",
    data,
  })
}
