<template>
  <div class="page"
       @scroll.passive="getScroll($event)">
    <headertab :activeBar="4"
               :hiddenHeader="hiddenHeader"></headertab>
    <div class="serve-classes">
      <div class="box">
        <div class="item-con">
          <div class="item"
               v-for="(list,index) in list"
               :key="index">
            <div class=" title">{{list.title}}</div>
            <div class="item-msg">{{list.msg}}</div>
            <div class="item-msg"><span class="money">{{list.money}}</span>元/年</div>
            <div class="button-label">免费试用</div>
          </div>
        </div>
      </div>
    </div>

    <bottom></bottom>
  </div>
</template>

<script>
import headertab from '@/components/headertab'
import bottom from '@/components/bottom'
import { globalMethods } from '../mixnis/index'

export default {
  name: 'Server',
  components: {
    headertab,
    bottom
  },
  mixins: [globalMethods],

  data() {
    return {
      list: [
        {
          title: '基础班',
          msg: '适用于对营销、智能化等高级功能有需求的场馆。',
          money: '3000',
        },
        {
          title: '私人部署',
          msg: '适用于有私有化部署定制化需求的企业',
          money: '****',
        },
      ]
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  methods: {
  }
}
</script>
<style scoped lang="scss">
.page {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.serve-classes {
  width: 100%;
  margin: 100px auto;
  padding: 0% 4%;
  box-sizing: border-box;
}
.box {
  padding: 10% 4% 4% 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/img/lALPD4PvMl_1irzNAZzNAXo_378_412.png"),
    url("../../assets/img/lALPD3lGtnGm21RgYA_96_96.png");
  background-repeat: no-repeat;
  background-position: top left, bottom right;
  background-size: 22% auto, 9% auto;
}
.item-con {
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
}

.item {
  width: 434px;
  height: 648px;
  padding: 2%;
  box-sizing: border-box;
  box-shadow: -3px 11px 24px 0px rgba(192, 192, 192, 0.14);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: #222222;
}
.title {
  font-size: 34px;
}
.item-msg {
  font-size: 18px;
}
.button-label {
  width: 220px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  background: linear-gradient(60deg, #2861ae, #34b05d);
  border-radius: 23px;
  color: #fff;
  font-size: 16px;
}
.money {
  font-size: 60px;
  font-weight: bold;
}

@media screen and (max-width: 1300px) {
  .item {
    width: 352px;
    height: 526px;
  }
  .title {
    font-size: 24px;
  }
  .item-msg {
    font-size: 16px;
  }
  .money {
    font-size: 34px;
    font-weight: bold;
  }
}

@media screen and (max-width: 1024px) {
  .serve-classes {
    background-size: 200px auto, 100px auto;
  }
  .item {
    width: 252px;
    height: 425px;
  }
}

@media screen and (max-width: 768px) {
  .serve-classes {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .box {
    min-width: 585px;
  }
  .item {
    min-width: 252px;
    height: 425px;
  }
}
</style>
