<template>
  <div class="boxCon signUp">
    <div class="text">免费试用创狗智云管理体系</div>
    <div class="text btn"
         @click="$router.push('/Tryout')">免费试用</div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.signUp {
  width: 85%;
  height: 200px;
  background-color: #3b58f4;
  border-radius: 20px;
  // margin: 0 0 5% 0;
  margin: 0 auto;
  margin-bottom: 5%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}
.text {
  color: #fff;
  font-weight: bold;
  font-size: 24px;
}
.btn {
  width: 100px;
  height: 30px;
  font-size: 14px;
  font-weight: normal;
  background-color: #fff;
  color: #3b58f4;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>