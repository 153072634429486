<template>
  <div class="second">
    <div class="web">
      <div class="msg-con"
           v-for="(list,index) in list"
           :key="index">

        <div class="msg-img"
             :style="{order:list.order?2:1}">
          <img :src="list.img"
               alt="">
        </div>

        <div class="msg-detail"
             :style="{order:list.order?1:2}">
          <div class="title-con">
            <titleMsg :titleMsg="list.title1"></titleMsg>
            <span class="node"></span>
            <titleMsg :titleMsg="list.title2"></titleMsg>
          </div>
          <div class="msg-item"
               v-for="(item,j) in list.msgs"
               :key="j">{{item}}</div>

        </div>
      </div>
    </div>

    <swiper class="mobile"
            ref="mySwiper"
            :options="swiperOptions"
            @slideChange="onSlideChange">

      <swiper-slide class="slide"
                    v-for="(list,index) in list"
                    :key="index">
        <titleMsg :titleMsg="list.title1"></titleMsg>
        <div class="title-con">
          <titleMsg :titleMsg="list.title2"></titleMsg>
          <span class="iconfont icon-jiantou"></span>
        </div>
        <div class="msg-mobile-con">
          <div class="msg-item"
               v-for="(item,j) in list.msgs"
               :key="j">{{item}}</div>
        </div>

        <div class="slide-img">
          <img :src="list.img"
               alt="">
        </div>

      </swiper-slide>

      <div class="swiper-pagination"
           slot="pagination">
        <div class="pagination-text">
          <span class="active">{{activeIndex+1}}</span>/{{list.length}}
        </div>
        <div class="pagination-info">
          <span v-for="(list,index) in list"
                :key="index"
                :class="{active:activeIndex===index?true:false}"></span>
        </div>
      </div>
    </swiper>
  </div>
</template>
<script>
import titleMsg from '@/components/titleMsg'
export default {
  name: 'second',
  components: {
    titleMsg
  },
  data () {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
      },
      activeIndex: 0,
      list: [
        {
          title1: '更数据化',
          title2: '全方位经营分析',
          img: require('../../../assets/img/product_data.png'),
          order: false,
          msgs: [
            '清晰化数据面板功能，门店日常经营、销售数据、排行等关键数据一 目了然，便于企业做出针对性策略决定；',
            '自动对用户渠道来源、转化率进行计算分析，为企业广告投放提供有 效的数据支撑；',
            '大数据信息采集，生成用户画像，便于制定精准定位营销策略；',
            '自动进行成本计算，生成财务数据报表，为拓店、营销活动等经营投 入提供数据支撑。',

          ]
        },
        {
          title1: '细分模块',
          title2: '精细化客户管理',
          order: true,
          img: require('../../../assets/img/product_customer.png'),
          msgs: [
            '支持课表启动/复制/可见显示控制/设置课程费等日常课程精细 化操作，一键进行课程管理；',
            '客户团课预约、补刷、私教等课程记录，清晰化课程安排；',
            '可在线进行发卡/开卡/停卡/延期/解除停卡/会员卡进销存等客户管理操作。',

          ]
        },
        {
          title1: '营销工具',
          title2: '助力主办业绩',
          img: require('../../../assets/img/product_market.png'),
          order: false,
          msgs: [
            '智能后台提供团购、秒杀、折扣等课程营销工具，不同营销玩法相结合，助力业绩提升；',
            '一键生成点赞分享、多人拼团等裂变活动，有效扩大传播规模，精准实现流量裂变；',
            '其他可自定义营销玩法，实现品牌创意营销。',

          ]
        },
        {
          title1: '品牌管理',
          title2: '更具个性化宣传',
          order: true,
          img: require('../../../assets/img/product_brand.png'),
          msgs: [
            '门店自定义在线LOGO形象，打造品牌独特调性；',
            '门店预约流程自定义设置，服务更具个性化；',
            '其他可专属自定义系统前端展示，确保企业品牌在用户端的有效输出，扩大企业影响力。',

          ]
        },

      ],

    }
  },
  methods: {
    onSlideChange (value) {
      this.activeIndex = value.activeIndex
    }
  }
}
</script>
<style scoped lang="scss">
.second {
  width: 100%;
  padding: 5% 4%;
  box-sizing: border-box;
  background-color: #fff;
}
.web {
  width: 100%;
}
.msg-con {
  width: 100%;
  margin: 10% 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.msg-img {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
  }
}
.msg-detail {
  flex: 2;
  order: 1;
}

.title-con {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10%;
  .node {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #222222;
    margin: 0 5%;
  }
  .iconfont {
    color: #222222;
    font-weight: bold;
    margin-left: 2%;
  }
}
.msg-item {
  width: 100%;
  padding: 0 3%;
  box-sizing: border-box;
  margin: 5% 0;
  display: flex;
  align-items: center;
}
.msg-item::before {
  display: block;
  content: "";
  min-width: 10px;
  height: 10px;
  margin-right: 5%;
  background-color: rgba(40, 97, 174, 1);
}

.mobile {
  width: 96%;
  display: none;
}

.slide-img {
  margin: 5% 0;
  img {
    width: 100%;
  }
}

.msg-mobile-con {
  width: 100%;
  height: 220px;
}

.swiper-pagination {
  position: relative;
  width: 92%;
  margin: 0 auto;
  font-weight: bold;
}
.pagination-text {
  text-align: left;
}
.pagination-text .active {
  font-size: 24px;
}
.pagination-info {
  width: 91%;
  height: 4px;
  display: flex;
  background: #f5f5f5;
  border-radius: 2px;
}
.pagination-info span {
  display: block;
  flex: 1;
  height: 100%;
  border-radius: 2px;
  background: transparent;
}
.pagination-info span.active {
  background: #222222;
}

@media screen and (max-width: 1024px) {
  .web {
    display: none;
  }
  .mobile {
    display: block;
  }
  .title-con {
    margin-bottom: 5%;
  }
  .msg-item {
    margin: 3% 0;
  }
}

@media screen and (max-width: 768px) {
  .msg-mobile-con {
    height: 300px;
  }
}
@media screen and (max-width: 414px) {
  .msg-mobile-con {
    height: 360px;
  }
}
</style>