<template>
  <div class="header-wrap"
    :class="{'header-wrap-hide':hiddenHeader,'header-wrap-show':!hiddenHeader}">
    <div class="head-con head-con-top">
      <blueLogo></blueLogo>
      <div class="header-right">
        <ul class="menu-top">
          <ol v-for="(list,index) in $t('m.navigationBar')"
            :key="index"
            :class="{active:activeBar === index&&index!==4?true:false,last:index==4?true:false}"
            @click="tabClick(index,list.path)">{{list.name}}</ol>
        </ul>
        <!-- <div class="header-label">
          <div class="signin">{{$t('m.signin')}}</div>
          <div class="signup"
               @click="$router.push('/Tryout')">{{$t('m.signup')}}</div>
          <div class="lang">
            <span @click="$i18n.locale = 'zh-CN'"
                  v-if="$i18n.locale === 'en-US'">中</span>
            <span @click="$i18n.locale = 'en-US'"
                  v-if="$i18n.locale === 'zh-CN'">EN</span>
          </div>
        </div> -->
      </div>
    </div>

    <div class="head-con head-con-right">
      <blueLogo></blueLogo>

      <span class="iconfont icon-gengduo"
        style="font-size:24px"
        @click="showLeftMenu =true"></span>

    </div>

    <div class="left-menu"
      :class="{showLeftMenu:showLeftMenu,hideLeftMenu:!showLeftMenu}">
      <div class="left-menu-header">
        <blueLogo></blueLogo>
        <span class="iconfont icon-gengduo"
          style="font-size:24px"
          @click="showLeftMenu =false"></span>
      </div>
      <!-- <div class="left-lang">
        <span @click="$i18n.locale = 'zh-CN'"
              :class="{disactive:$i18n.locale === 'zh-CN'?true:false}">中</span>
        &nbsp;/
        <span @click="$i18n.locale = 'en-US'"
              :class="{disactive:$i18n.locale === 'en-US'?true:false}">English</span>
      </div> -->
      <ul>
        <ol v-for="(list,index) in $t('m.navigationBar')"
          :key="index"
          @click="tabClick(index,list.path),showLeftMenu =false">{{list.name}}</ol>
      </ul>
    </div>

  </div>
</template>
<script>
import blueLogo from './blueLogo'

export default {
  name: 'headertab',
  components: {
    blueLogo,
  },
  props: {
    activeBar: {
      required: true,
      default: 0,
      type: Number
    },
    hiddenHeader: {
      required: true,
      default: false,
      type: Boolean
    }
  },
  watch: {
    hiddenHeader() {
      this.showLeftMenu = false
    }
  },
  data() {
    return {
      showLeftMenu: false,
      activeTab: 0,
    }
  },
  created() {
  },
  methods: {
    tabClick(index, path) {
      this.activeTab = index
      if (this.$route.path === path) return
      this.$router.push(path)
    },
  }
}
</script>
<style scoped lang="scss">
.header-wrap {
  width: 100%;
  position: fixed;
  top: 0;
  background-color: #fff;
  color: #222222;
  z-index: 9999;
}
.header-wrap-hide {
  transform: translateY(-97px);
  transition: transform 0.25s ease-out;
}
.header-wrap-show {
  transform: translateY(0px);
  translate: transform 0.25s ease-in;
}
.head-con {
  width: 87%;
  height: 72px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.head-con-top {
  .header-right,
  .header-label {
    height: 100%;
    display: flex;
    align-items: center;
    div {
      margin: 0 15px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .signup {
      width: 118px;
      height: 40px;
      color: #ffffff;
      background: linear-gradient(60deg, #2861ae, #34b05d);
      border-radius: 20px;
    }
  }
  .lang,
  .menu-top,
  ol {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .menu-top {
    display: flex;
    ol {
      // flex: 1;
      height: 100%;
      margin: 0 20px;
      font-weight: 500;
      box-sizing: border-box;
    }
    ol.active {
      padding-top: 4px;
      border-bottom: 4px transparent solid;
      border-image: linear-gradient(90deg, #2861ae, #34b05d) 1 2;
    }
    ol.last {
      width: 118px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      background: linear-gradient(60deg, #2861ae, #34b05d);
      border-radius: 20px;
    }
  }
}

.head-con-right {
  display: none;
  padding: 0 20px;
  box-sizing: border-box;
}

.left-menu {
  width: 100vw;
  font-size: 24px;
  font-weight: bold;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: -100vw;
  padding: 0 5% 5% 5%;
  box-sizing: border-box;
  ul {
    padding-top: 30px;
  }
  ol {
    width: 100%;
    height: 56px;
    line-height: 56px;
  }
}

.showLeftMenu {
  transform: translateX(-100vw);
  transition: transform 0.25s ease-out;
}
.hideLeftMenu {
  transform: translateX(0px);
  transition: transform 0.25s ease-in;
}
.left-menu-header {
  width: 100%;
  height: 56px;
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.left-lang {
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: right;
  span.disactive {
    color: #999;
  }
}

@media screen and (max-width: 768px) {
  .header-wrap {
    background-color: #fff;
  }
  .head-con {
    width: 100%;
    height: 56px;
  }
  .head-con-top {
    display: none;
  }

  .head-con-right {
    display: flex;
  }

  .left-menu {
    ol {
      font-size: 16px;
    }
  }
}
</style>