export const globalMethods = {
  data() {
    return {
      top: 0,
      hiddenHeader: false,
    }
  },
  methods: {
    getScroll(event) {
      let scrollTop = event.target.scrollTop
      scrollTop >= 100
        ? (this.hiddenHeader = true)
        : (this.hiddenHeader = false)
      let scroll = scrollTop - this.top
      this.top = scrollTop
      if (scroll < 0) {
        this.hiddenHeader = false
      } else {
        this.hiddenHeader = true
      }
    },
  },
}
