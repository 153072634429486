<template>
  <div class="four">
    <div class="title-con">
      <titleMsg titleMsg="智能硬件"></titleMsg>
      <span class="web-span">,</span>
      <div class="title-con-item">
        <titleMsg titleMsg="智能场馆控制"></titleMsg>
        <span class="iconfont icon-jiantou"></span>
      </div>

    </div>

    <div class="content">
      <div class="item"
           v-for="(list,index) in list"
           :key="index">
        <div class="msg">{{list.msg}}</div>
        <img :src="list.img"
             alt="">
      </div>
    </div>

  </div>
</template>
<script>
import titleMsg from '@/components/titleMsg'
export default {
  components: {
    titleMsg
  },
  data () {
    return {
      list: [
        {

          msg: '人流量检测',
          img: require('../../../assets/img/product_traffic.png')
        },
        {

          msg: '心率检测',
          img: require('../../../assets/img/product_heart.png')
        },
        {
          msg: '舞蹈动作检测',
          img: require('../../../assets/img/product_dance.png')
        },
        {
          msg: '体脂检测',
          img: require('../../../assets/img/product_physical.png')
        },
        {

          msg: '人脸识别',
          img: require('../../../assets/img/product_face.png')
        },
        {
          msg: '无感知签到',
          img: require('../../../assets/img/product_sign.png')
        },
        {
          msg: '智能监控',
          img: require('../../../assets/img/product_monitor.png')
        },
        {
          msg: '智能手环',
          img: require('../../../assets/img/product_bracelet.png')
        },
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.four {
  width: 100%;
  padding: 5% 4%;
  background-color: #ffffff;
}
.web-span {
  font-size: 34px;
}
.iconfont {
  display: none;
  margin-left: 15px;
}
.title-con {
  display: flex;
  align-items: center;
  justify-content: center;
}
.content {
  width: 100%;
  margin: 6% auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.item {
  width: 25%;
  padding: 1% 1%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  img {
    display: block;
    width: 120px;
    height: 120px;
  }
}

.msg {
  width: 100%;
  text-align: center;
  margin: 10% auto;
}

@media screen and (max-width: 1024px) {
  .item {
    border: 0;
    margin: 2% 0;
    img {
      width: 100px;
      height: 100px;
      order: 1;
    }
  }
}
@media screen and (max-width: 768px) {
  .four {
    background-color: #fafafa;
  }
  .title-con {
    display: block;
  }
  .title-con-item {
    display: flex;
    align-items: center;
  }
  .web-span {
    display: none;
  }
  .icon-jiantou {
    display: block;
  }
  .item {
    border: 0;
    margin: 2% 0;
    padding: 0;
    img {
      width: 80px;
      height: 80px;
      order: 1;
    }
  }
}
@media screen and (max-width: 540px) {
  .msg {
    font-size: 14px;
  }
  .item {
    border: 0;
    margin: 2% 0;
    img {
      width: 50px;
      height: 50px;
      order: 1;
    }
  }
}
</style>