<template>
  <div class="five">
    <div class="title-con">
      <titleMsg titleMsg="合作伙伴"></titleMsg>
      <titleMsg titleMsg="多种角色全面覆盖"
                class="mobile"></titleMsg>
    </div>

    <div class="five-con">
      <span class="iconfont icon-arrow-left"
            @click="toleft"></span>
      <div class="con">
        <swiper ref="mySwiper"
                :options="swiperOptions"
                class="swiper">
          <swiper-slide class="slide"
                        v-for="(list,index) in ours"
                        :key="index">
            <div class="img-con">
              <img :src="list"
                   alt="">
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <span class="iconfont icon-jiantou1"
            @click="toright()"></span>
    </div>
  </div>
</template>
<script>
import titleMsg from '../../../components/titleMsg.vue'
export default {
  components: {
    titleMsg
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
        slidesPerView: 1,
        spaceBetween: 40,
        initialSlide: 0,
        breakpoints: {
          321: {  //当屏幕宽度大于等于768
            slidesPerView: 2,
            spaceBetween: 10
          },
          1280: {  //当屏幕宽度大于等于1280
            slidesPerView: 3,
            spaceBetween: 30
          }
        }
      },
      activeIndex: 0,
      ours: [
        require('../../../assets/img/home_partner_1.png'),
        require('../../../assets/img/home_partner_2.png'),
        require('../../../assets/img/home_partner_3.png'),
      ]
    }
  },
  methods: {
    toleft() {
      console.log(this.activeIndex, this.ours.length)
      if (this.activeIndex <= 0) return
      this.activeIndex--
      this.swiper.slideTo(this.activeIndex, 1000, false)

    },
    toright() {
      console.log(this.activeIndex, this.ours.length)
      if (this.activeIndex + 3 >= this.ours.length) return
      this.activeIndex++
      this.swiper.slideTo(this.activeIndex, 1000, false)
    }
  }

}
</script>
<style lang="scss" scoped>
.five {
  width: 100%;
  padding: 5% 4%;
  box-sizing: border-box;
}
.five-con {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 80px 0;
}
.iconfont {
  font-size: 40px;
}

.title-con {
  width: 100%;
  display: flex;
  justify-content: center;
}

.mobile {
  display: none;
}

.con {
  width: 65%;
  min-width: 250px;
  background: #ffffff;
  box-shadow: -3px 0px 38px 2px rgba(192, 192, 192, 0.16);
  border-radius: 230px;
  display: flex;
  align-items: center;
  padding: 6% 4%;
  box-sizing: border-box;
}
.img-con {
  display: flex;
  justify-content: center;
  // width: 80%;
  img {
    width: 153px;
    height: auto;
  }
}
.swiper-wrapper {
  align-items: center;
}
@media screen and (max-width: 1024px) {
  .img-con {
    img {
      width: 80px;
    }
  }
  .iconfont {
    font-size: 24px;
  }
  .five-con {
    margin: 40px 0;
  }
}

@media screen and (max-width: 768px) {
  .title-con {
    // justify-content: flex-start;
    display: block;
  }
  .mobile {
    display: block;
  }
}
</style>
<style lang="scss">
.five-con {
  .swiper-wrapper {
    align-items: center;
  }
}
</style>