<template>
  <div class="page"
       @scroll.passive="getScroll($event)">
    <headertab :activeBar="0"
               :hiddenHeader="hiddenHeader"></headertab>
    <swiper ref="mySwiper"
            :options="swiperOptions">
      <swiper-slide v-for="(list,index) in swipers"
                    :key="index">
        <div class="swiper">
          <div class="swiper-des1">创狗智云，赋能增长</div>
          <div class="swiper-des2">专业、可靠、简洁、打造私域运营体系助力企业实现精细化，专业化管理</div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination"
           slot="pagination"></div>
    </swiper>

    <first></first>
    <second></second>
    <div class="third">
      <div>{{$t('m.home.third.title')}}</div>
      <div>{{$t('m.home.third.msg')}}</div>
    </div>
    <four></four>
    <five></five>
    <bottom></bottom>
  </div>
</template>

<script>
import headertab from '@/components/headertab'
import first from './components/first'
import second from './components/second'
import four from './components/four'
import five from './components/five'
import bottom from '@/components/bottom'
import { globalMethods } from '../mixnis/index'
export default {
  name: 'Home',
  components: {
    headertab,
    first,
    second,
    four,
    five,
    bottom
  },
  mixins: [globalMethods],
  data() {
    return {
      swiperValue: 0,
      swipers: [
        '../../assets/img/home_banner.png',
      ],
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
      },

    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  methods: {

  }
}
</script>
<style scoped lang="scss">
.page {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.swiper {
  width: 100%;
  height: 773px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../assets/img/home_banner.png");
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 10%;
  div {
    color: #fff;
    margin: 3% 0;
  }
}
.swiper-des1 {
  font-family: "DINCondBlack";
  font-size: 66px;
}
.swiper-des2 {
  font-size: 30px;
}
.title {
  font-size: 44px;
  color: #222222;
  font-weight: bold;
  padding: 0 30px;
  box-sizing: border-box;
}
.third {
  width: 100%;
  height: 334px;
  background-image: url("../../assets/img/home_data_bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  color: #fff;
  align-items: center;
  justify-content: center;
  div:nth-child(1) {
    font-size: 42px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  div:nth-child(2) {
    width: 120px;
    height: 40px;
    background: #ffffff;
    color: #222222;
    border: 1px solid #222222;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 1400px) {
  .swiper {
    width: 100%;
    height: 580px;
  }
  .swiper-des1 {
    font-family: "DINCondBlack";
    font-size: 44px;
  }
  .swiper-des2 {
    font-size: 22px;
  }
}

@media screen and (max-width: 1024px) {
  .swiper-des1 {
    font-family: "DINCondBlack";
    font-size: 36px;
  }
  .swiper-des2 {
    font-size: 18px;
  }
  .third {
    height: 200px;
    div:nth-child(1) {
      font-size: 30px;
    }
    div:nth-child(2) {
      width: 90px;
      height: 30px;
      background: #ffffff;
      color: #222222;
      border: 1px solid #222222;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
@media screen and (max-width: 768px) {
  .swiper {
    width: 100%;
    height: 400px;
  }
  .swiper-des1 {
    font-family: "DINCondBlack";
    font-size: 28px;
  }
  .swiper-des2 {
    font-size: 16px;
  }
  title {
    font-size: 30px;
  }
  .third {
    display: none;
  }
}
</style>
