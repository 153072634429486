<template>
  <div class="third">
    <div class="title-con">
      <titleMsg titleMsg="解锁更多功能"></titleMsg>
      <span class="web-span">,</span>
      <div class="title-con-item">
        <titleMsg titleMsg="用户体验更佳"></titleMsg>
        <span class="iconfont icon-jiantou"></span>
      </div>

    </div>

    <div class="content">
      <div class="item"
           v-for="(list,index) in list"
           :key="index">
        <div class="sub-title"> {{list.title}}</div>
        <div class="msg">{{list.msg}}</div>
        <img :src="list.img"
             alt="">
      </div>
    </div>

  </div>
</template>
<script>
import titleMsg from '@/components/titleMsg'
export default {
  components: {
    titleMsg
  },
  data () {
    return {
      list: [
        {
          title: '建立会员体系',
          msg: '搭建专属会员成长积分体系，结合个性化激励机制，带来不一样的会员体验，增加用户黏性。',
          img: require('../../../assets/img/product_members.png')
        },
        {
          title: '会员自助业务',
          msg: '线上便捷平台，会员可自助进行快捷操作、办理相关业务，简化流程节省时间。',
          img: require('../../../assets/img/product_business.png')
        },
        {
          title: '丰富会员权益',
          msg: '丰富的会员专享权益，提高会员省份的认同感与价值感，与用户间建立深度联结。',
          img: require('../../../assets/img/product_benefit.png')
        },
        {
          title: '线上签订合同',
          msg: '简化合同签订流程，线上随时随地快速签约，合同长期云端存储，提升安全保障。',
          img: require('../../../assets/img/product_contract.png')
        },
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.third {
  width: 100%;
  padding: 5% 4%;
  background-color: #fafafa;
}
.web-span {
  font-size: 34px;
}
.iconfont {
  display: none;
  margin-left: 15px;
}
.title-con {
  display: flex;
  align-items: center;
  justify-content: center;
}
.content {
  width: 100%;
  margin: 10% auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item {
  width: 368px;
  height: 550px;
  border: 1px solid#EDEDED;
  padding: 2% 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  img {
    display: block;
    width: 61.5%;
    height: 37.8%;
    margin: 3% 0;
  }
}

.sub-title {
  width: 100%;
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  margin: 8% 0;
}
.msg {
  width: 100%;
  height: 40%;
}

.mobile {
  // width: 100%;
  // display: none;
}
@media screen and (max-width: 1660px) {
  .item {
    width: 318px;
    height: 475px;
  }
}
@media screen and (max-width: 1400px) {
  .item {
    width: 268px;
    height: 401px;
  }
}
@media screen and (max-width: 1200px) {
  .item {
    width: 230px;
    height: 344px;
  }
}
@media screen and (max-width: 1024px) {
  .third {
    background: #fff;
  }
  .content {
    flex-wrap: wrap;
    margin: 0% auto;
    justify-content: space-between;
  }
  .title-con {
    display: block;
  }
  .web-span {
    display: none;
  }
  .title-con-item {
    display: flex;
    align-items: center;
  }
  .iconfont {
    display: block;
  }
  .item {
    width: 45%;
    align-items: flex-start;
    border: 0;
    margin: 5% 0;
    img {
      width: 178px;
      height: 178px;
      order: 1;
    }
  }
  .sub-title {
    text-align: left;
    order: 2;
  }
  .msg {
    order: 3;
  }
}
@media screen and (max-width: 768px) {
  .item {
    img {
      width: 133px;
      height: 133px;
    }
  }
  .sub-title {
    font-size: 20px;
  }
}
@media screen and (max-width: 500px) {
  .item {
    width: 50%;
    // align-items: center;
    // img {
    //   width: 87px;
    //   height: 87px;
    // }
  }
}
</style>