<template>
  <div class="first">
    <div class="title-con">
      <titleMsg titleMsg="创狗智云"></titleMsg>
      <span class="title-split">,</span>
      <titleMsg titleMsg="一个产品体系四个维度"></titleMsg>
    </div>
    <div class="label">
      <div class="label-con">
        <div class="label-item"
             v-for="(list,index) in $t('m.home.FirstLabel.labels')"
             :key="index">
          <div class="label-item-img">
            <img :src='list.img'
                 alt="" />
          </div>
          <div class="label-item-title">{{list.title}}</div>
          <div class="label-item-msg">{{list.msg}}</div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import titleMsg from '@/components/titleMsg'
export default {
  components: {
    titleMsg
  }
}
</script>
<style scoped lang="scss">
.first {
  width: 100%;
  padding: 3% 0;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.title-con {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title-split {
  font-size: 44px;
}
.label {
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  margin: 5% 0;
}
.label-con {
  width: 100%;
  display: flex;
  justify-content: center;
}
.label-item {
  min-width: 260px;
  height: 340px;
  background-color: #fff;
  border-radius: 10px;
  margin: 0 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.label-item:nth-child(1):hover {
  border: 1px solid #6445c4;
}
.label-item:nth-child(2):hover {
  border: 1px solid #69ccbf;
}
.label-item:nth-child(3):hover {
  border: 1px solid #2092ff;
}
.label-item:nth-child(4):hover {
  border: 1px solid #ff4574;
}
.label::-webkit-scrollbar {
  display: none;
}
.label-item-img {
  width: 110px;
  height: 110px;
  margin-bottom: 20px;
  img {
    display: block;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
.label-item-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
}
.label-item-msg {
  padding: 0 10px;
  text-align: center;
}
@media screen and (max-width: 1400px) {
  .label-item-title {
    font-size: 20px;
  }
  .label-item-img {
    width: 80px;
    height: 80px;
  }
  .label-item {
    min-width: 200px;
    height: 248px;
  }
}
@media screen and (max-width: 1024px) {
  .first {
    background-color: #fff;
  }
  .label {
    width: 100%;
    overflow-x: scroll;
  }
  .label-con {
    // width: 1094px;
    justify-content: flex-start;
  }
  .label-item {
    background-color: #fafafa;
    min-width: 220px;
    height: 290px;
    margin: 0 10px;
  }
  .label-item-img {
    width: 80px;
    height: 85px;
    margin-bottom: 20px;
  }
  .label-item-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .title-con {
    display: block;
  }
  .title-split {
    display: none;
  }
}
</style>