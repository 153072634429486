import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home/Index.vue'
import Product from '@/views/Product/index.vue'
import Server from '@/views/Server/index.vue'
import Team from '@/views/Team/index.vue'
import Purchase from '@/views/Purchase/index'
import Tryout from '@/views/Tryout/index'


Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/Product',
        name: 'Product',
        component: Product
    },
    {
        path: '/Server',
        name: 'Server',
        component: Server
    },
    {
        path: '/Team',
        name: 'Team',
        component: Team
    },
    {
        path: '/Purchase',
        name: 'Purchase',
        component: Purchase
    },
    {
        path: '/Tryout',
        name: 'Tryout',
        component: Tryout
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router