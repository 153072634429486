import axios from "axios"
import config from "../config"
import { Message } from "view-design"
import qs from "qs"

// 创建axios实例
const service = axios.create({
  baseURL: config.baseUrl,
  timeout: 5000,
})

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    if (config.method === "post") {
      config.data = config.data || {}
      config.data.brandId = 1
      config.data = qs.stringify(config.data)
      // config.headers["Content-Type"] = "application/x-www-form-urlencoded" //formData
      // config.headers["Content-Type"] = "multipart/form-data" //json形式一半用来上传文件
      // config.headers["Content-Type"] = "application/json"//json形式
    } else if (config.method === "get") {
      config.params = config.params || {}
      config.params.brandId = 1
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    let { status, data, message } = response.data
    if ([404, 500].includes(status)) {
      Message.error("网路错误！")
    }
    if (status === 400) {
      Message.error(message)
    }
    if (status === 200) {
      return data
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default service
