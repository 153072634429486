<template>
  <div class="bottom">

    <div class="bottom-item"
         style="align-items:flex-end">
      <div>
        <div class="tel">Tel: 021-62036392</div>
        <div class="bottom-time-web">{{$t('m.bottom.workTime')}}</div>
      </div>
      <blueLogo></blueLogo>
    </div>
    <div class="line"></div>
    <div class="bottom-item">

      <div class="bottom-ma-web">
        <div class="bottom-text">公司地址：中国（上海）自由贸易试验区临港新片区云汉路979号2楼</div>
        <!-- <div class="bottom-text">公司邮箱：business@TronGou.COM</div> -->

        <div class="bottom-icon">
          <div>
            <img src="../assets/img/5.jpg"
                 alt="">
            <div>创狗智云公众号</div>
          </div>
          <div>
            <img src="../assets/img/4.jpg"
                 alt="">
            <div>创狗智云客服号</div>
          </div>

          <!-- <i class="iconfont icon-weixin"
             style="margin-right:10px"></i>
          <i class="iconfont icon-kefu"></i> -->
        </div>
      </div>
      <div class="bottom-tabs">
        <div v-for="(list,index) in $t('m.navigationBar')"
             :key="index"
             @click="tabClick(list.path)">{{list.name}}</div>
      </div>
    </div>
    <div class="logo bottom-item">
      {{$t('m.bottom.logoName')}}
    </div>
    <div class="logo bottom-item">
      {{$t('m.bottom.logoSign')}}
    </div>
  </div>
</template>
<script>
import blueLogo from './blueLogo'
export default {
  components: {
    blueLogo,
  },
  methods: {
    tabClick(path) {
      if (this.$route.path === path) return
      this.$router.push(path)
    }
  }
}
</script>
<style lang="scss" scoped>
.bottom {
  width: 100%;
  padding: 3% 6.5% 3% 6.5%;
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../assets/img/home_bottom_bg.png");
  border: 0;
  border-top: 4px transparent solid;
  border-image: linear-gradient(90deg, #2861ae, #34b05d) 1 2;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.bottom-item {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}
.tel {
  font-size: 30px;
  margin: 10px 0;
}
.bottom-icon {
  // width: 252px;
  display: flex;
  align-items: center;
  font-size: 10px;
  img {
    width: 100px;
    height: auto;
    margin: 10px;
  }
  div {
    text-align: center;
  }
}
.line {
  width: 100%;
  height: 0px;
  border-top: 1px solid rgba(34, 34, 34, 0.7);
}
.bottom-tabs {
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  div {
    font-size: 20px;
    min-width: 60px;
    margin: 20px 0;
  }
}
.bottom-ma {
  width: 131px;
  height: 131px;
  img {
    width: 100%;
    height: 100%;
  }
}
.logo {
  justify-content: center;
  text-align: center;
}
.bottom-text {
  margin: 3% 0;
}
@media screen and(max-width:768px) {
  .bottom-tabs {
    width: 140px;
    div {
      min-width: 50px;
      margin: 15px 0;
    }
  }
  .tel {
    font-size: 24px;
  }
  .logo {
    font-size: 12px;
  }
}
</style>