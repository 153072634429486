<template>
  <div class="page"
       @scroll.passive="getScroll($event)">
    <headertab :activeBar="2"
               :hiddenHeader="hiddenHeader"></headertab>
    <div class="top-img"></div>
    <serviceProcess></serviceProcess>
    <ServiceHighlights></ServiceHighlights>
    <serviceSecurity></serviceSecurity>
    <signUp></signUp>
    <bottom></bottom>

  </div>
</template>

<script>
import headertab from '@/components/headertab'
import serviceProcess from './components/serviceProcess'
import ServiceHighlights from './components/ServiceHighlights'
import serviceSecurity from './components/serviceSecurity'
import bottom from '@/components/bottom'
import signUp from '@/components/signUp'
import { globalMethods } from '../mixnis/index'

export default {
  name: 'Server',
  components: {
    headertab,
    signUp,
    serviceProcess,
    ServiceHighlights,
    serviceSecurity,
    bottom
  },
  mixins: [globalMethods],

  data() {
    return {
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  methods: {
  }
}
</script>
<style scoped lang="scss">
.page {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.top-img {
  width: 100%;
  height: 590px;
  background-image: url("../../assets/img/service_banner.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 768px) {
  .top-img {
    height: 310px;
  }
}
</style>
