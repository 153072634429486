<template>
  <div class="serve-height">
    <div class="title-con">
      <titleMsg titleMsg="产品服务亮点"></titleMsg>
      <span class="iconfont icon-jiantou"></span>
    </div>
    <div class="web">
      <div class="item"
           v-for="(list,index) in list"
           :key="index"
           @mouseenter.capture="list.active= true"
           @mouseout.capture="list.active= false"
           :class="{active:list.active?true:false}">
        <img :src="list.active?list.iconActivited:list.icon"
             alt="">
        <div class="item-title">{{list.title}}</div>
        <div class="item-msg">{{list.msg}}</div>

      </div>
    </div>
    <div class="mobile">
      <div class="item"
           v-for="(list,index) in list"
           :key="index"
           :class="{active:list.active?true:false}">
        <img :src="list.active?list.iconActivited:list.icon"
             alt="">
        <div>
          <div class="item-title">{{list.title}}</div>
          <div class="item-msg">{{list.msg}}</div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import titleMsg from '@/components/titleMsg'
export default {
  components: {
    titleMsg
  },
  data () {
    return {
      list: [
        {
          title: '舞蹈专业知识库',
          msg: '解读企业现状，分析优化品牌管理，提供具有可行性的品牌推广模式。',
          icon: require('../../../assets/img/service_dance_no.png'),
          iconActivited: require('../../../assets/img/service_dance.png'),
          active: false
        },
        {
          title: '销售锦囊',
          msg: '提供销售培训服务，舞蹈行业金牌销售经验分享，提供销售锦囊。',
          icon: require('../../../assets/img/service_sales_no.png'),
          iconActivited: require('../../../assets/img/service_sales.png'),
          active: false
        },
        {
          title: '师资匹配',
          msg: '根据用户偏好数据，智能匹配优秀舞蹈教师资源。',
          icon: require('../../../assets/img/service_teacher_no.png'),
          iconActivited: require('../../../assets/img/service_teacher.png'),
          active: false
        },
        {
          title: '营销推广',
          msg: '个性化分析用户画像开展营销活动，通过大数据获取全渠道商机。',
          icon: require('../../../assets/img/service_market_no.png'),
          iconActivited: require('../../../assets/img/service_market.png'),
          active: false
        },
        {
          title: '品牌推广',
          msg: '解读企业现状，分析优化品牌管理，提供具有可行性的品牌推广模式。',
          icon: require('../../../assets/img/service_brand_no.png'),
          iconActivited: require('../../../assets/img/service_brand.png'),
          active: false
        },
      ]
    }
  },
  methods: {
    itemEnter (list) {
      console.log(list)
      list.active = true
    }
  }
}
</script>
<style lang="scss" scoped>
.serve-height {
  width: 100%;
  padding: 5% 4%;
  background-color: #fafafa;
}
.title-con {
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-jiantou {
  display: none;
}
.web {
  width: 100%;
  padding: 5% 0;
  display: flex;
  justify-content: space-around;
}
.item {
  width: 220px;
  height: 290px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: space-around;
  padding: 1%;
  color: #101010;
  img {
    width: 50px;
    height: 3.5rem;
  }
}
.item-title {
  font-size: 18px;
  font-weight: bold;
}
.item-msg {
  font-size: 14px;
}
.item.active {
  background-color: #2861ae;
  color: #fff;
}
.mobile {
  width: 100%;
  display: none;
  flex-direction: column;
}
@media screen and (max-width: 1300px) {
  .item {
    width: 180px;
    height: 250px;
    img {
      width: 50px;
      height: 3.5rem;
    }
  }
}

@media screen and (max-width: 1024px) {
  .serve-height {
    background-color: #fff;
  }
  .web {
    display: none;
  }
  .mobile {
    display: flex;
  }
  .title-con {
    justify-content: flex-start;
  }
  .icon-jiantou {
    display: block;
  }
  .item {
    width: 100%;
    height: 100px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0;
    img {
      margin-right: 20px;
    }
  }
}
</style>