export const m = {
    navigationBar: [{
            id: 0,
            name: 'Home',
            path: '/'
        },
        {
            id: 1,
            name: 'Product',
            path: ''
        },
        {
            id: 2,
            name: 'Service',
            path: ''
        },
        {
            id: 3,
            name: 'About',
            path: ''
        },
        {
            id: 4,
            name: 'Pricing',
            path: ''
        }
    ],
    signin: 'Signin',
    signup: 'Signup',
    home: {
        FirstLabel: {
            title1: 'TronGou Cloud,',
            title2: 'Four dimensions of a product system',
            labels: [{
                    title: 'Systemize',
                    img: require('../assets/img/lALPD3W5M6kGMIN4eA_120_120.png'),
                    msg: 'Intelligent system makes operation easier'
                },
                {
                    title: 'Intelligent',
                    img: require('../assets/img/lALPD3zUMPKQ-pN4eA_120_120.png'),
                    msg: 'Encapsulate data, from management to service'
                },
                {
                    title: 'Digitization',
                    img: require('../assets/img/lALPD3zUMPKTMIZ4eA_120_120.png'),
                    msg: 'Big data analysis helps enterprises develop'
                },
                {
                    title: 'Servicing',
                    img: require('../assets/img/lALPD26eNl9-CHB4eA_120_120.png'),
                    msg: 'Professional team, service first'
                },
            ]
        },

        secondLabel: {
            first: {
                title1: 'Web management',
                title2: 'Big data helps enterprises develop',
                list: ['Fine member management', 'Sub-authority management mode', 'Venue management', 'Staff management', 'Online one-click scheduling', 'Multidimensional data report', 'Electronic contract', 'group message', 'Multiple marketing methods', '...']
            },
            second: {
                title1: 'WeChat class appointment system',
                title2: 'Fully cover the needs of users and enterprises',
                list: ['Fast appointment', 'View the appointment records at any time', 'Check in', 'Classroom rental', 'Points rights', 'Self-service card service', 'Electronic contract signing online', '...']
            }
        },

        third: {
            title: 'Use data to make operations easier',
            msg: 'More'
        }

    },
    four: {
        title: 'Individual needs of the enterprise, Comprehensive coverage of multiple roles',
        list: [
            '1.One-stop intelligent workbench, the system helps to carry out efficient and convenient digital management of each link',
            '2.The system analyzes customer data in different dimensions to provide a feasible basis for managers to make decisions',
            '3.Intelligent analysis of employee growth trajectories to improve store management efficiency'
        ]
    },
    bottom: {
        workTime: 'office hours:  10:00 - 19:00(weekdays)',
        logoName: '©2020 Chuangou Zhiyun-Internet Company For The Dance Industry',
        logoSign: '苏ICP备123456号-2',
    }
}