import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"

Vue.config.productionTip = false

import VueAwesomeSwiper from "vue-awesome-swiper"
import "swiper/swiper-bundle.css"

Vue.use(VueAwesomeSwiper)

//阿里图标库
import "@/assets/fontIcon/iconfont.css"

import VueI18n from "vue-i18n"
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: "zh-CN",
  messages: {
    "zh-CN": require("./lang/zh"),
    "en-US": require("./lang/en"),
  },
})

// 全局实例方法引入
import globalValidator from "./libs/globalValidator"
Vue.use(globalValidator)

//iview组件

import {
  Icon,
  Tooltip,
  Tabs,
  Form,
  Input,
  Row,
  Col,
  Message,
  TabPane,
} from "view-design"
Vue.component("Icon", Icon)
Vue.component("Tooltip", Tooltip)
Vue.component("Tabs", Tabs)
Vue.component("Form", Form)
Vue.component("FormItem", Form.Item)
Vue.component("Input", Input)
Vue.component("Row", Row)
Vue.component("Col", Col)
Vue.component("TabPane", TabPane)
Message.config({
  top: 80,
  duration: 3,
})
Vue.prototype.$Message = Message

import "view-design/dist/styles/iview.css"

//全局样式文件
import "@/assets/css/main.css"

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app")
