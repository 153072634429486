<template>
  <div class="title">
    {{titleMsg}}
  </div>
</template>
<script>
export default {
  name: 'titleMsg',
  props: {
    titleMsg: {
      required: true,
      type: String
    },
    center: {
      default: false,
      type: Boolean
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  font-size: 30px;
  color: #222222;
  font-weight: bold;
  text-align: center;
}
@media screen and (max-width: 1024px) {
  .title {
    font-size: 26px;
    text-align: left;
  }
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 22px;
  }
}
</style>