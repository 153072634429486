// import Vue from "vue"

export default {
  install(Vue) {
    Vue.prototype.$phoneValidate = (rule, value, callback) => {
      if (rule.required && !value) {
        return callback(new Error("请输入手机号！"))
      } else {
        let reg = /^(0|86|17951)?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/
        if (!reg.test(value)) {
          return callback(new Error("手机号格式不正确！"))
        } else {
          callback()
        }
      }
    }
  },
}
